import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"

import { FEATURE_DRAWING_MODE } from "library/utilities/constants"
import { getActiveTooth } from "./teeth"
import {
  DrawingAction,
  DrawingAnnotation,
  DrawingLineThickness,
} from "../types/drawing"
import { AnnotationName, RestorationSubtype } from "../types/adjustmentTypes"
import {
  getAllAdditions,
  getAllUserChanges,
  getDetectionsToRenderForAllTeeth,
  getKind,
  getShowLetterBasedPeri,
} from "./serverData"
import { getCariesPro } from "./image"
import { getAnnotationNameType } from "../../utilities/annotations"
import { crownOrBridgeOverride } from "../../utilities/tooth"
import { Kind } from "../types/serverDataTypes"

export const getDrawingActionState = (state: RootState) =>
  state.drawing.drawingAction
export const getDrawingLineThickness = (state: RootState) =>
  state.drawing.drawingLineThickness
export const getIsDrawing = (state: RootState) => state.drawing.drawing
export const getShowDrawingWarning = (state: RootState) =>
  state.drawing.showDrawingWarning
export const getIsErasing = (state: RootState) => state.drawing.isErasing
export const getActiveCariesProId = (state: RootState) =>
  state.drawing.activeCariesProId
export const getActivePeriId = (state: RootState) => state.drawing.activePeriId

export const getDrawingAction = createSelector(
  [getDrawingActionState, getActiveTooth, getShowLetterBasedPeri],
  (drawingActionState, activeTooth, showLetterBasedPeri) =>
    !activeTooth &&
    !showLetterBasedPeri &&
    drawingActionState !== DrawingAction.annotate
      ? DrawingAction.select
      : drawingActionState
)

export const getIsDrawingModeActive = (state: RootState) =>
  FEATURE_DRAWING_MODE && state.drawing.drawingModeActive
export const getDrawingAnnotation = (state: RootState) =>
  FEATURE_DRAWING_MODE
    ? state.drawing.drawingAnnotation
    : DrawingAnnotation.none

export const getMasksToDraw = createSelector(
  [
    getAllAdditions,
    getActiveTooth,
    getDrawingAction,
    getActiveCariesProId,
    getCariesPro,
    getDetectionsToRenderForAllTeeth,
    getAllUserChanges,
    getActivePeriId,
    getShowLetterBasedPeri,
  ],
  (
    allAdditions,
    activeTooth,
    drawingAction,
    activeCariesProId,
    cariesPro,
    detectionsToRenderForAllTeeth,
    allUserChanges,
    activePeriId,
    showLetterBasedPeri
  ) => {
    if (drawingAction == DrawingAction.annotate) {
      return allAdditions.filter((a) => a.type === AnnotationName.annotate)
    }

    const rejectedIds = allUserChanges
      .filter((a) => a.action === "rejected")
      .map((change) => change.annotationId)

    // Get bridge/crown detection that was replaced by bridge/crown
    const replacedBridgeOrCrown = detectionsToRenderForAllTeeth.filter(
      (d) =>
        !!crownOrBridgeOverride(null, allAdditions, d) &&
        activeTooth === d.toothName
    )[0]

    // AI detections to draw
    const filteredDetections = detectionsToRenderForAllTeeth
      .filter((d) => !rejectedIds.includes(d.id))
      .map((detection) => ({
        id: detection.id,
        toothName: detection.toothName,
        type: getAnnotationNameType(detection.subtype), // Handle SDM cases as well
        subtype: RestorationSubtype[detection.subtype as RestorationSubtype],
        mask: detection.mask,
        isReplacedSubtype: false,
        rejectedIds: [],
      }))

    const combined = allAdditions
      .map((a) => ({
        ...a,
        ...(replacedBridgeOrCrown && {
          mask: !a.isReplacedSubtype ? replacedBridgeOrCrown.mask : a.mask,
          isReplacedSubtype: true,
          rejectedIds: [replacedBridgeOrCrown?.id] || [],
        }),
      }))
      .concat(filteredDetections)

    if (showLetterBasedPeri) {
      return combined.filter((a) => a.id === activePeriId)
    }
    if (cariesPro && drawingAction === DrawingAction.caries) {
      return combined.filter((a) => a.id === activeCariesProId)
    }

    return combined.filter(
      (a) =>
        a.toothName === activeTooth &&
        (a.subtype
          ? a.subtype ===
            RestorationSubtype[drawingAction as keyof typeof RestorationSubtype]
          : a.type ===
            AnnotationName[drawingAction as keyof typeof AnnotationName])
    )
  }
)

export const getDrawingButtonThicknessSettings = createSelector(
  [getKind],
  (kind) => {
    // To ensure a more consistent drawing button to xray image ratio, we increase the drawing button size for PERI and BW images
    return [
      DrawingLineThickness.thin,
      DrawingLineThickness.medium,
      DrawingLineThickness.thick,
    ].map((a) => (kind === Kind.Peri || kind === Kind.Bw ? a * 2 : a))
  }
)
export const getShowDrawAnnotationButton = (state: RootState) =>
  state.drawing.showDrawAnnotationButton
